import { BRAND_NAME } from 'src/constants/environment';
import { BrandConfigType } from './config';

// auto copied before start/build from ./src/whitelabels
import { wlConfig } from './wlConfig';

// How to get values in bash
// echo -n 'novatti' | md5sum | awk '{print $1}'

export const DECTA = '54cfc9272d9aeb761f475b688a519197';
export const NOVATTI = '50f918415c5ad3ddc771754ed4b51e5f';
export const G2G = 'da99144d6a2c86557d56d4c69c8020f6';
export const CARDAQ = '463d775af7b29e835b1cdce03b96f557';
export const NL77 = 'b293a70fbbb0376e135f531af26b862d';
export const NETWORX = 'bf48deab665dfd6463352569a3ee4f31';
export const NOVOWORLD = 'd2c8c8b94f635c97ce86ad14a645c589';
export const LIPAPAP = '50a832aae50dd401d09cdb7e03b03475';
export const GTWLINK = 'a37bf49c90462186ab817e7c13517c05';
export const VECTOR = '6ba8844da718b4a65f60dbfd0d92d6ef';
export const BREEZE = 'e97abd871ea2793ffa98f430ed268c35';
export const ARBIPAY = '34a30ed12135a047df9a026c4d8d03b7';
export const XCHECKOUTS = '5ebdf27859a481a4c5695c7801af4989';
export const CARDIFY = 'e6aba9f99b6b5d5b6182fab0ea7a2f5d';

const AMEX_WLS = [NOVATTI];

export const AMEX_ENABLED = AMEX_WLS.includes(BRAND_NAME);

export const BRAND_URL = (wlConfig as unknown as BrandConfigType).url;
